export const about = `Welcome to Tallinn. Interact with select five troubled hotspots on a reactive map of Tallinn City Centre. Each hotspot leads to a 360 view of the street to inform why the current solutions aren’t sufficient nor safe for any road user. Each hotspot also provides a proposed solution to the present issues. Listen to the voiceovers of a local explaining his experience as a cyclist in Tallinn. Additional videos and texts allow you to learn more about the benefits of cycling in the contexts of environment and health, and paint a clear picture of the urgency of the matter within Tallinn.

Vicious Cyclist is a contribution to the ongoing global conversation surrounding the switch towards a more sustainable way of life as well as Tallinn's local conversation discussing the importance of cycling infrastructure within a modern city.`;

export const credits = `Director, videographer, editor: Eger Tiitus
Developer and photographer: Natali Bergman
Scriptwriters: Mariette Vassus, Eger Tiitus
Photo editor: mitte_tallinn

Voiceover: Eger Tiitus

Music credits:

Spearfisher - Hope and Heisenberg
℗ Futur 2020

Spearfisher - Blood Meridian
℗ Futur 2020

Video credits:

How to Design Bicycle-friendly Cities
By Mikael Colville-Andersen
© The Life-Sized City 2020

Laser: Marii Karell: “See, mis juhtus meie eksperimendis tomatitega, võib kahjuks juhtuda liikluses päriselt inimestega.”
By Marii Karell - Telegeenius
© TV3 Estonia 2020

Elevation data: Estonian Land Board 2020`;
