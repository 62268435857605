import React from "react";

import "./popup.styles.scss";

const Popup = ({ title, content, onCrossClick }) => {
  return (
    <div className="popup-container">
      <div className="close-icon-container" onClick={onCrossClick}>
        <img src="./icons/x-icon.png" alt="Close" />
      </div>
      <div className="title">{title}</div>
      <hr />
      <div className="content">{content}</div>
    </div>
  );
};

export default Popup;
