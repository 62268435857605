import React, { useState, useRef, useEffect } from "react";

import "./play-overlay.styles.scss";

const PlayOverlay = () => {
  const [hidden, setHidden] = useState(false);
  const [play, setPlay] = useState(false);
  const tuneRef = useRef();
  const voiceOverRef = useRef();

  useEffect(() => {
    document.addEventListener("visibilitychange", function () {
      if (document.visibilityState === "visible" && play) {
        tuneRef.current.play();
        voiceOverRef.current.play();
      } else {
        tuneRef.current.pause();
        voiceOverRef.current.pause();
      }
    });

    voiceOverRef.current.addEventListener("ended", () => {
      voiceOverRef.current.muted = true;
    });
  });

  const onPlayClicked = () => {
    setPlay(true);
    tuneRef.current.volume = 0.2;
    tuneRef.current.play();
    voiceOverRef.current.play();
    setHidden(true);
  };

  return (
    <>
      <div className={`overlay-container ${hidden ? "hidden" : ""}`}>
        <div className="title-container">
          <div className="title">VICIOUS CYCLIST</div>
          <div className="warning">
            Please use Google Chrome or Mozilla Firefox for the best experience
          </div>
        </div>
        <div className="instructions-container">
          <div className="icons">
            <div className="image-container">
              <img
                src="./icons/Mouse_left_click_icon.png"
                alt="Mouse left click"
              />
            </div>
            <div className="image-container">
              <img src="./icons/Mouse_scroll_icon.png" alt="Mouse scroll" />
            </div>
            <div className="image-container">
              <img src="./icons/Arrow_keys_icon.png" alt="Arrow keys" />
            </div>
          </div>
          <div className="instructions">
            <p>
              Hold down the left mouse button to pan and tilt the map. Left
              click to select hotspots.
            </p>
            <p>Scroll to zoom in and out.</p>
            <p>Use the arrow keys to hover around the map.</p>
          </div>
        </div>
        <div className="play" onClick={() => onPlayClicked()}>
          <img src="./icons/play-icon.png" alt="Play icon" className="icon" />
        </div>
      </div>
      <audio ref={tuneRef} src="./audio/Hope_and_Heisenberg.mp3" loop />
      <audio ref={voiceOverRef} src="./audio/Map_voiceover_with_silence.mp3" />
    </>
  );
};

export default PlayOverlay;
