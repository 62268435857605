import React, { useState } from "react";
import { useSpring, a } from "react-spring/three";
import SpriteText from "three-spritetext";

const Hotspot = ({ coordinates, title, link }) => {
  const [hovered, setHovered] = useState(false);
  const props = useSpring({
    scale: hovered ? [1.5, 1.5, 1.5] : [1, 1, 1],
    color: hovered ? "#3b429f" : "#ff5a13",
  });

  const handleClick = () => {
    window.open(link, "_blank", "noopener,noreferrer");
  };

  const text = new SpriteText(title, 8, "#000000");

  return (
    <>
      <primitive
        object={text}
        backgroundColor={"#CDCDCDcc"}
        borderColor={"#989898cc"}
        borderWidth={1}
        padding={1.5}
        fontFace={"Didot"}
        fontWeight={"Bold"}
        position={[coordinates[0], coordinates[1] + 15, coordinates[2]]}
      />
      <a.mesh
        onPointerOver={() => setHovered(true)}
        onPointerOut={() => setHovered(false)}
        castShadow
        onClick={() => handleClick()}
        scale={props.scale}
        position={coordinates}
      >
        <sphereBufferGeometry attach="geometry" args={[5, 32, 32]} />
        <a.meshPhysicalMaterial attach="material" color={props.color} />
      </a.mesh>
    </>
  );
};

export default Hotspot;
