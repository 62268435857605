import React, { useRef, useEffect } from "react";
import { OrbitControls } from "drei";

import MapLayer from "./map-layer.component";
import Hotspot from "./hotspot.component";

import { Vector3 } from "three";

import dataJSON from "../data/data-new.json";

const Map = () => {
  const controlsRef = useRef();

  const handleClick = (e) => {
    controlsRef.current.autoRotate = false;
  };

  document.querySelector("canvas").addEventListener("click", handleClick);

  useEffect(() => {
    if (controlsRef.current) {
      const controls = controlsRef.current;
      var minPan = new Vector3(-1000, -500, -700);
      var maxPan = new Vector3(1000, 500, 1200);
      var _v = new Vector3();

      controls.addEventListener("change", function () {
        _v.copy(controls.target);
        controls.target.clamp(minPan, maxPan);
        _v.sub(controls.target);
        controls.object.position.sub(_v);
      });
    }
  });

  return (
    <>
      <MapLayer data={dataJSON} />
      <Hotspot
        title={"St. Charles' Avenue"}
        coordinates={[140, 50, -260]}
        link={
          "https://player.wondavr.com/p/45824518-9875-4741-bf5e-b8c67631f78e#Kaarli360"
        }
      />
      <Hotspot
        title={"Tehnika Street"}
        coordinates={[873.5, 50, 38.5]}
        link={
          "https://player.wondavr.com/p/45824518-9875-4741-bf5e-b8c67631f78e#Tehnika360"
        }
      />
      <Hotspot
        title={"Pärnu Motorway"}
        coordinates={[-167.5, 50, -653.5]}
        link={
          "https://player.wondavr.com/p/45824518-9875-4741-bf5e-b8c67631f78e#Parnu360"
        }
      />
      <Hotspot
        title={"Estonia Avenue"}
        coordinates={[-776, 50, 93]}
        link={
          "https://player.wondavr.com/p/45824518-9875-4741-bf5e-b8c67631f78e#Estonia360"
        }
      />
      <Hotspot
        title={"Pikk Street"}
        coordinates={[-247, 50, 399]}
        link={
          "https://player.wondavr.com/p/45824518-9875-4741-bf5e-b8c67631f78e#Pikk360"
        }
      />
      <OrbitControls
        ref={controlsRef}
        screenSpacePanning={false}
        keyPanSpeed={10}
        maxPolarAngle={Math.PI / 2.5}
        minPolarAngle={Math.PI / 4}
        maxDistance={400}
        minDistance={200}
        autoRotate={true}
        autoRotateSpeed={0.5}
      />
    </>
  );
};

export default Map;
