import React from "react";
import { Canvas } from "react-three-fiber";

import Header from "./components/header/header.component";
import PlayOverlay from "./components/play-overlay/play-overlay.component";

import Map from "./components/map.component";

import "./App.css";

function App() {
  return (
    <>
      <Header />
      <PlayOverlay />
      <Canvas camera={{ position: [0, 0, 300], fov: 60 }}>
        <ambientLight />
        <spotLight position={[890, 150, 1090]} penumbra={1} intensity={1} />
        <fog attach="fog" args={["white", 5, 1150]} />
        <Map />
      </Canvas>
    </>
  );
}

export default App;
