import React, { useRef, useEffect } from "react";
import * as THREE from "three";

const geometry = new THREE.BufferGeometry();
const material = new THREE.PointsMaterial();

const textureLoader = new THREE.TextureLoader();

const MapLayer = ({ data }) => {
  const pointsRef = useRef();
  const vertices = [];

  data.points.forEach((point) => {
    const x = point.x;
    const y = point.z;
    const z = point.y;

    vertices.push(-x, y, z);
  });

  material.map = textureLoader.load("./textures/black.png");
  material.alphaTest = 0.5;
  material.size = 1;

  useEffect(() => {
    const points = pointsRef.current;

    geometry.setAttribute(
      "position",
      new THREE.Float32BufferAttribute(vertices, 3)
    );

    points.geometry = geometry;
    points.material = material;
  });

  return <points ref={pointsRef} />;
};

export default MapLayer;
