import React, { useState } from "react";

import "./header.styles.scss";

import Popup from "../popup/popup.component";

import { about, credits } from "../../data/texts";

const Header = () => {
  const [aboutHidden, setAboutHidden] = useState(true);
  const [creditsHidden, setCreditsHidden] = useState(true);

  const handleCrossClick = () => {
    setAboutHidden(true);
    setCreditsHidden(true);
  };

  const showHideAbout = () => {
    setAboutHidden(!aboutHidden);
    if (!creditsHidden) setCreditsHidden(true);
  };

  const showHideCredits = () => {
    setCreditsHidden(!creditsHidden);
    if (!aboutHidden) setAboutHidden(true);
  };

  return (
    <>
      <div className="header-container">
        <div className="header">
          <div className="logo-container">VICIOUS CYCLIST</div>
          <div className="options-container">
            <div className="option" onClick={() => showHideAbout()}>
              ABOUT
            </div>
            <div className="option" onClick={() => showHideCredits()}>
              CREDITS
            </div>
          </div>
        </div>
      </div>
      {!aboutHidden ? (
        <Popup
          title={"About this project"}
          content={about}
          onCrossClick={() => handleCrossClick()}
        />
      ) : (
        ""
      )}
      {!creditsHidden ? (
        <Popup
          title={"Credits"}
          content={credits}
          onCrossClick={() => handleCrossClick()}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default Header;
